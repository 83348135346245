<template>
  <div class="container-fluid">
    <spot-type-form
      :loading="loading"
      :spotTypeData="spotType"
      :formErrors="formErrors"
      :hideOrganization="!!globalOrganization"
      @spotTypeSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseSpotTypeModal="onCloseSpotTypeModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSpotType from "../defaultSpotType";
import SpotTypeForm from "../partials/SpotTypeForm.vue";
import { mapGetters } from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    SpotTypeForm,
  },

  mixins: [alertLeave],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  data() {
    return {
      spotType: cloneDeep(defaultSpotType),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.spotType.organization = this.globalOrganization;
  },

  methods: {
    async handleSubmit(spotType) {
      this.loading = true;

      const spotTypeData = cloneDeep(spotType);
      delete spotTypeData.id;

      try {
        await this.$store.dispatch("spotTypes/add", spotTypeData);
        this.$notify({
          type: "success",
          message: this.$t("SPOT_TYPES.SPOT_TYPE_ADDED"),
        });
        const spotType = await this.$store.getters["spotTypes/spotType"];
        this.$emit("onViewSpotType", spotType, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseSpotTypeModal() {
      this.$emit("onCloseSpotTypeModal");
    },
  },
};
</script>
