export default {
  type: "spot-types",
  name: null,
  excerpt: "",
  created_at: null,
  show_as_filter: false,
  relationshipNames: ["category","organization"],
  category: {
    type: "spot-categories",
    id: null,
  },
};
