<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!spotType">
      <span class="loader"></span>
    </span>
    <spot-type-form
      v-if="spotType"
      :loading="loading"
      :spotTypeData="spotType"
      :formErrors="formErrors"
      :hideOrganization="true"
      @spotTypeSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseSpotTypeModal="onCloseSpotTypeModal"
    />
  </div>
</template>

<script>
import SpotTypeForm from "../partials/SpotTypeForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { SpotTypeForm },

  mixins: [alertLeave],

  props: {
    spotTypeId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      spotType: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("spotTypes/get", this.spotTypeId);
        this.spotType = this.$store.getters["spotTypes/spotType"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(spotType) {
      this.loading = true;
      const spotTypeData = cloneDeep(spotType);

      try {
        await this.$store.dispatch("spotTypes/update", spotTypeData);
        this.$notify({
          type: "success",
          message: this.$t("SPOT_TYPES.SPOT_TYPE_UPDATED"),
        });
        const spotType = await this.$store.getters["spotTypes/spotType"];
        this.$emit("onViewSpotType", spotType, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseSpotTypeModal() {
      this.$emit("onViewSpotType");
    },
  },
};
</script>
